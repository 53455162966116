
<template>
  <div class="main-content">

    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>

    <div class="row">
      <div class="col-md-12">
        <h2>Bonus Points report</h2>
      </div>
    </div>

    <hr/>


    <div class="row">
      <div class="col-md-6">
        <h4>Filters:</h4>
      </div>
      <div class="col-md-6">
        <button class="btn btn-success float-right" v-b-modal.export-transactions> Export </button>
      </div>
    </div>

    <hr/>

    <div class="row" style="margin-bottom: 20px;">
      <div class="col-md-6">
        <div>
          By date:
        </div>

        <date-picker   :lang="lang" v-model="start_date" valueType="format" :first-day-of-week="1"></date-picker> to
        <date-picker   :lang="lang" v-model="end_date" valueType="format" :first-day-of-week="1"></date-picker> &nbsp;&nbsp;
        <button type="button" class="btn btn-danger" @click="getTransactionsData">Filter</button>

      </div>
      <div class="col-md-2">
        <div>
          By Loyalty Rule:
        </div>
        <select class="form-control" v-model="loyaltyRuleId" @change="getTransactionsData">
          <option value="0" > All </option>
          <option :value="rule.id" v-for="rule in rules"> {{ rule.ruleName }}</option>
        </select>
      </div>
    </div>
    <br/>


    <div >
      <b-table striped hover :items="transactions" :fields="columns">
        <template #cell(no)="data">
          {{ data.index + 1 }}
        </template>
        <template v-slot:cell(customer)="data">
          {{ data.value.name }}
        </template>

        <template v-slot:cell(outlet)="data">
          <span>{{ data.value.name }}</span>
        </template>
        <template v-slot:cell(amount)="data">
          <span>KES. {{ data.value | number('0,0') }}</span>
        </template>
        <template v-slot:cell(transactionType)="data">
        <span  class="badge badge-danger"  v-if="data.value == 'ACCRUAL'">
							Accrual
						</span>
          <span class="badge badge-success"  v-else >
							Redemption
        </span>
        </template>

        <template v-slot:cell(transactionApprovalStatus)="data">
          <div v-if="data.value != null" >
							<span  class="badge badge-success"  v-if="data.value == 'APPROVED'" >
								Accepted
							</span>
            <span  class="badge badge-warning"  v-else-if="data.value == 'PENDING'" >
								Pending
							</span>

            <span class="badge badge-danger"  v-else >
								Reversed
							</span>
          </div>
          <div v-else>
							<span   class="badge badge-info" >
								Accepted
							</span>
          </div>
        </template>

      </b-table>
    </div>

    <div class="row">
      <div class="col-md-12">

        <nav aria-label="Page navigation float-right" style="margin-top: 20px;">
          <ul class="pagination justify-content-center">
            <li v-if="pagination.current_page > 1" class="page-item"><a @click.prevent="changePage(pagination.current_page - 1)" class="page-link" href="#">Previous</a></li>
            <li v-for="page in pagesNumber"
                v-bind:class="[ page == isActived ? 'active' : '']" class="page-item"><a @click.prevent="changePage(page - 1)" class="page-link" href="#">{{ page }}</a></li>
            <li v-if="pagination.current_page < pagination.last_page"  class="page-item"><a  @click.prevent="changePage(pagination.current_page + 1)"class="page-link" href="#">Next</a></li>
          </ul>
        </nav>
      </div>
    </div>

    <b-modal id="export-transactions" title="Export transactions" @cancel="true" @ok="exportToExcel"   centered>
      <form action="#" class="form-horizontal"  method="post">
        <input type="hidden" name="_token" />
        <div class="form-body">
          <div class="row" >
            <div class="col-md-12">
              <div class="form-group ">
                <label class="control-label ">Please enter your email address:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="email_address" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </b-modal>


  </div>
</template>
<script>

  import { mapGetters, mapActions } from "vuex";

  import Loading from 'vue-loading-overlay';
  // Import stylesheet
  import 'vue-loading-overlay/dist/vue-loading.css'
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
  import Multiselect from 'vue-multiselect'

export default {
  data() {
    return {
      email_address:"",
      reversal_comments:"",
      disabled:false,
      isLoading: false,
      fullPage: true,
      pagination: {
        total: 0,
        per_page: 2,
        from: 1,
        to: 0,
        current_page: 0
      },
      loyaltyStats:{
        totalPoints:0,
        pointsAccruedToday:0,
        totalPointsToday:0,
        redeemedToday:0,
      },
      merchantStats:{
        pointsValue:0,
        pointsAccrued:0,
        pointsAccruedToday:0,
        redeemedToday:0
      },
      lang: {
        days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        pickers: ['next 7 days', 'next 30 days', 'previous 7 days', 'previous 30 days'],
        placeholder: {
          date: 'Select Date',
          dateRange: 'Select Date Range'
        }
      },
      offset: 4,
      columns:["No","transactionDate","customer","phoneNumber","outlet","amount","loyaltyPoints","referenceNo","transactionApprovalStatus"],
      transactions:[],
      start_date:"all",
      end_date:"all",
      outlet:null,
      outlets:[],
      transaction:{
        customer:{
          name:""
        },
        loyaltyPoints:0
      },
      transaction_type:"Accrual",
      approval_type:"ALL",
      loyaltyRuleId:0,
      rules:[]
    };
  },
  computed:{
    isActived: function () {
      return this.pagination.current_page + 1;
    },
    pagesNumber: function () {
      if (!this.pagination.to) {
        return [];
      }
      var from = this.pagination.current_page - this.offset;
      if (from < 1) {
        from = 1;
      }
      var to = from + (this.offset * 2);
      if (to >= this.pagination.last_page) {
        to = this.pagination.last_page;
      }
      var pagesArray = [];
      while (from <= to) {
        pagesArray.push(from);
        from++;
      }
      return pagesArray;
    },
    permissions(){
      return localStorage.getItem("permissions");
    },
    role(){
      return localStorage.getItem("role");
    }
  },
  components: {
    Loading,
    DatePicker,
    Multiselect
  },
  mounted (){
    this.getTransactionsData();
    this.getRulesData();
  },
  methods: {
    ...mapActions(["getRules","getBonusTransactions", "getOutlets","getLoyaltyStats","reverseTransaction","getOutletsByUserId","getMerchantStats","exportBonusTransactions"]),

    exportToExcel(){

      let self = this;
      this.isLoading = true;

      this.exportBonusTransactions({from_date:this.start_date,to_date:this.end_date,loyalty_rule_id:this.loyaltyRuleId, username: this.email_address})
        .then(function () {

          self.isLoading = false;

          self.$bvToast.toast("Export request received. We'll email you the report once its ready", {
            title: 'Success',
            variant: 'success',
            autoHideDelay: 5000,
            appendToast: false
          });

        })
        .catch(function (error) {

          self.isLoading = false;

          console.log(error);
        });
    },
    getRulesData(){
      let self = this;
      this.getRules()
          .then(function (rules) {
            self.rules = rules;
          })
          .catch(function (error) {

          })
    },
    changePage: function (page) {
      this.pagination.current_page = page;
      this.getTransactionsData();
    },

    getTransactionsData(){
      let self = this;

      let outlet_id = 0;

      if(this.outlet != null){
        outlet_id = this.outlet.id;
      }

      this.isLoading = true;

      this.getBonusTransactions({page: this.pagination.current_page, from_date:this.start_date,to_date:this.end_date, loyaltyRuleId: this.loyaltyRuleId})
        .then(function (transactions) {

          self.isLoading = false;

          self.transactions = [];

          let transactions_arr = transactions.content;

          transactions_arr.forEach(function (transaction) {


            let single_loyalty_trans = transaction;

            if(transaction.customer != null){
              single_loyalty_trans.phoneNumber = transaction.customer.mobileNumber;
            }
            else{
              single_loyalty_trans.phoneNumber = "";
            }


            self.transactions.push(single_loyalty_trans)
          });


          self.pagination.current_page = transactions.number;
          self.pagination.total = transactions.totalElements;
          self.pagination.per_page = transactions.numberOfElements;
          self.pagination.from = transactions.pageable.offset + 1 ;
          self.pagination.to = transactions.pageable.pageSize;
          self.pagination.last_page = transactions.totalPages;
        })
        .catch(function (error) {
          self.isLoading = false;
          console.log(error)
        })
    },
    clearFilter(){
      this.start_date = "all";
      this.end_date = "all";
      this.transaction_type = "all";

      if(this.role != 'Merchant'){
        this.outlet = null;
      }

    },
  }
};
</script>
